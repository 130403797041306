<template>
  <!-- 行业筛选框 -->
  <div class="hyfl_tottle">
    <!-- 行业 -->
    <div
      class="select-tit">Industry:</div>
    <el-cascader
      v-model="industryValue"
      :options="industryOptions"
      :props="{ expandTrigger: 'hover' }"
      :style="{marginLeft: this.$language === 'cn' ? '0' : '3px'}"
      placeholder="All"
      size="mini"
      style="width: 700px;"
      clearable
      @change="handleChange">
    </el-cascader>
  </div>
</template>

<script>
//
import { industryList } from '@/api/cceec/constant'

export default {
  data() {
    return {
      industry: false,
      industryValue: null,
      hoverIndex1: 1,
      selectIndex1: null,
      selectIndex2: null,
      industryOptions: [],
      industryOptions1: [],
      industryOptions2: [],
      industryOptions3: []
    }
  },
  created() {
    this.getIndustryList()
  },
  methods: {
    handleChange(val) {
      console.log(val)
      if (val.length === 2) {
        setTimeout(() => {
          if (val[1] === 0) {
            this.$emit('handleIndustryFirstChange', val[0])
          } else {
            this.$emit('handleIndustrySecondChange', val[0], val[1])
          }
        }, 300)
      } else {
        this.$emit('handleAllIndustry')
      }
    },
    //  全部 点击
    handleAllClick() {
      this.industry = false
      this.selectIndex1 = null
      this.selectIndex2 = null
      this.hoverIndex1 = 1
      this.$emit('handleAllIndustry')
    },
    //  一级菜单 点击
    handleItemClick(item) {
      this.industry = true
      this.selectIndex1 = item.value
      this.selectIndex2 = null
      this.$emit('handleIndustryFirstChange', item.value)
    },
    //  二级菜单 点击
    handleSecondItemClick(first, second) {
      this.industry = true
      this.selectIndex1 = first.value
      this.selectIndex2 = second.value
      this.$emit('handleIndustrySecondChange', first.value, second.value)
    },
    //  鼠标移入
    handleMousemove(item) {
      this.hoverIndex1 = item.value
    },
    //  鼠标移出
    handleMouseout() {
      if (this.selectIndex1) {
        this.hoverIndex1 = this.selectIndex1
      } else {
        this.hoverIndex1 = 1
      }
    },
    getIndustryList() {
      const data = {
        level: 1
      }
      industryList(data).then(res => {
        // console.log(res)
        const list = res.data
        const industryOptions = []
        list.forEach(item => {
          const it = {
            value: item.id,
            label: this.$language === 'cn' ? item.classifyName : item.english,
            children: []
          }
          const data = {
            level: 2,
            fatherId: it.value
          }
          industryList(data).then(res => {
            // console.log(res)
            const list = res.data
            const secondIndustrys = [{
              value: 0,
              label: 'All'
            }]
            list.forEach(item => {
              const it2 = {
                value: item.id,
                label: this.$language === 'cn' ? item.classifyName : item.english
              }
              secondIndustrys.push(it2)
            })
            it.children = secondIndustrys
          })
          industryOptions.push(it)
        })
        this.industryOptions = industryOptions
        this.industryOptions1 = [industryOptions[0], industryOptions[1], industryOptions[2], industryOptions[3]]
        this.industryOptions2 = [industryOptions[4], industryOptions[5]]
        this.industryOptions3 = [industryOptions[6], industryOptions[7], industryOptions[8]]
        console.log(industryOptions)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .hyfl_tottle {
    display: flex;
    align-items: center;
    margin-top: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 950px;
    /*height: 120px;*/
    word-break: break-all;
    white-space: normal;
    border-radius: 5px;
    border: 1px solid rgb(204, 204, 204);
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
    /* title */
    .select-tit {
      font-size: 15px;
      font-weight: bold;
      color: #333;
      display: inline-block;
      margin-right: 5px;
      margin-left: 5px;
      vertical-align: top;
      /*margin-top: 5px;*/
    }
    /* 全部 */
    .select-all {
      font-size: 15px;
      color: #333;
      display: inline-block;
      font-weight: bold;
      vertical-align: top;
      margin-top: 5px;
      cursor: pointer;
    }
    /**/
    .select-contet {
      position: relative;
      float: right;
      margin-right: 15px;
      width: 815px;
      a {
        display: flex;
        flex-direction: column;
        width: auto;
        text-align: center;
        font-size: 14px;
        padding-right: 20px;
        padding-left: 20px;
        color: #000;
        margin-right: 10px;
        cursor: pointer;
        /*background-color: yellow;*/
        span {
          padding: 0 10px;
        }
        img {
          width: 12px;
          height: 6px;
          margin: 0 auto;
        }
      }
      a:hover {
        /*padding-bottom: 10px;*/
        span {
          color: #ffffff !important;
          border-radius: 5px;
          background-color: #465364;
        }
      }
      .erJmenu {
        width: 795px;
        border-radius: 10px;
        height: auto;
        border: 1px #ccc solid;
        padding: 5px 10px 5px 10px;
        a {
          display: inline-block;
          padding-left: 10px;
          padding-right: 10px;
        }
        a:hover {
          color: #0077cc;
          text-decoration: underline;
        }
      }
    }
  }
  .first_select {
    color: #0077cc;
    text-decoration: underline;
  }
  .select_item {
    color: #ffffff !important;
    border-radius: 5px;
    background-color: #465364;
  }
  /deep/ .el-input {
    .el-input__inner {
      border: 1px solid #767676;
    }
    .el-input__inner:focus {
      border: 1px solid #858585;
    }
    .el-input__inner:hover {
      border: 1px solid #858585;
    }
    .el-input__icon {
      color: #767676;
    }
  }
</style>
